import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'

//banner轮播
var interleaveOffset = 0.5; //视差比值
var bannerswiper = new Swiper(".section1 .mySwiper", {
  loop: true,
  speed: 800,
  watchSlidesProgress: true,
  autoplay: {
    delay: 6000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
    waitForTransition: false,
  },
  pagination: {// 分页
    el: ".section1 .swiper-pagination",
    clickable: true,
  },
  on:{
    progress: function(swiper) {
		  for (var i = 0; i < swiper.slides.length; i++) {
			var slideProgress = swiper.slides[i].progress;
			var innerOffset = swiper.width * interleaveOffset;
			var innerTranslate = slideProgress * innerOffset;
			swiper.slides[i].querySelector(".slide-inner").style.transform =
			  "translate3d(" + innerTranslate + "px, 0, 0)";
		  }      
		},
		touchStart: function(swiper) {
		  for (var i = 0; i < swiper.slides.length; i++) {
			swiper.slides[i].style.transition = "";
		  }
		},
		setTransition: function(swiper, speed) {
		  for (var i = 0; i < swiper.slides.length; i++) {
			swiper.slides[i].style.transition = speed + "ms";
			swiper.slides[i].querySelector(".slide-inner").style.transition =
			  speed + "ms";
		  }
		}
  },
})

// vision
var swiper1 = new Swiper(".section3 .img-swiper .mySwiper", {
  speed: 600,
  effect : 'fade',
  fadeEffect: {
    crossFade: true,
  },
  allowTouchMove: false,
})
var swiper2 = new Swiper(".section3 .text-swiper .mySwiper", {
  speed: 600,
  effect : 'fade',
  fadeEffect: {
    crossFade: true,
  },
  allowTouchMove: false,
  pagination: {// 分页
    el: ".section3 .text-swiper .swiper-pagination",
    type : 'progressbar',
    progressbarOpposite: true,
  },
  on:{
    slideChangeTransitionStart: function(){
      swiper1.slideTo(this.activeIndex)
    },
  },
})

//solutions
var sec3Offset = .8; //视差比值
var swiper3 = new Swiper(".section4 .img-swiper .mySwiper", {
  speed: 1000,
  direction: 'vertical',
  allowTouchMove: false,
  watchSlidesProgress: true,
  on:{
    init: function(swiper){
      $('.section4 .sec4-text .tab a').eq(this.activeIndex).addClass('active')
    },
    slideChangeTransitionStart: function(){
      $('.section4 .sec4-text .tab a').eq(this.activeIndex).addClass('active').siblings().removeClass('active')
    },
    progress: function(swiper) {
      for (var i = 0; i < swiper.slides.length; i++) {
      var slideProgress = swiper.slides[i].progress;
      var innerOffset = swiper.height * sec3Offset;
      var innerTranslate = slideProgress * innerOffset;
      // console.log(swiper.slides[i],slideProgress,innerOffset,innerTranslate)
      swiper.slides[i].querySelector(".slide-inner").style.transform =
        "translate3d(0," + innerTranslate + "px, 0)";
      }
    },
    touchStart: function(swiper) {
      for (var i = 0; i < swiper.slides.length; i++) {
      swiper.slides[i].style.transition = "";
      }
    },
    setTransition: function(swiper, speed) {
      for (var i = 0; i < swiper.slides.length; i++) {
      swiper.slides[i].style.transition = speed + "ms";
      swiper.slides[i].querySelector(".slide-inner").style.transition =
        speed + "ms";
      }
    }
  },
})
// $('.section4 .sec4-text .tab a').on('click',function(){
//   let h = $('.section4 .sec4-zw div').eq($(this).index() + 1).offset().top
//   $("html, body").animate({
//     scrollTop: h
//   }, {duration: 500,easing: "swing"});
//   return false;
// })


let sec4contTopArr = []
$(document).scroll(function () {
  let scroH = $(document).scrollTop(); //滚动高度
  let viewH = $(window).height(); //可见高度
  let contentH = $(document).height(); //内容高度

  //who we are
  if(scroH > 10){
    $('.section2').addClass('is-inview')
  }else{
    $('.section2').removeClass('is-inview')
  }

  //vision
	let section3_top = $('.section3').offset().top
	let section3_btm = $('.section3').offset().top + $('.section3').outerHeight() - viewH

  if($(window).width() > 1024){
    if (scroH <= section3_top) {
      $('.section3 .sec3-cont').removeClass('fixed')
    }else if (scroH > section3_top && scroH < section3_btm) {
      $('.section3 .sec3-cont').addClass('fixed').removeClass('abs')
    }else{
      $('.section3 .sec3-cont').addClass('abs').removeClass('fixed')
    }

    let sec3_1 =  $('.section3 .sec3-zw>div').eq(0).offset().top
    let sec3_2 =  $('.section3 .sec3-zw>div').eq(1).offset().top
    if (scroH < sec3_2) {
      swiper2.slideTo(0)
    }else if(scroH >= sec3_2){
      swiper2.slideTo(1)
    }
  }

  //solutions
  let section4_top = $('.section4').offset().top
	let section4_btm = $('.section4').offset().top + $('.section4').outerHeight() - viewH

  if (scroH <= section4_top) {
    $('.section4 .sec4-cont').removeClass('fixed')
  }else if (scroH > section4_top && scroH < section4_btm) {
    $('.section4 .sec4-cont').addClass('fixed').removeClass('abs')
  }else{
    $('.section4 .sec4-cont').addClass('abs').removeClass('fixed')
  }

  if(sec4contTopArr.length == 0 || sec4contTopArr.slice(-1) != $('.section4 .sec4-zw div').last().offset().top){
    sec4contTopArr = []
    $('.section4 .sec4-zw div').each(function(index){
      sec4contTopArr.push($(this).offset().top)
    })
  }
  for(let i=0; i < sec4contTopArr.length; i++){
    if(scroH < sec4contTopArr[1]){
      swiper3.slideTo(0)
    }
    if(i > 0 && i < sec4contTopArr.length - 1){
      if(scroH > sec4contTopArr[i] && scroH < sec4contTopArr[i+1]){
        swiper3.slideTo(i)
      }
    }
    if(scroH > sec4contTopArr.slice(-1)){
      swiper3.slideTo(sec4contTopArr.length-1)
    }
  }
});